import React, { Component } from 'react'
import Link from '../utils/link'
import { Symbol } from './icons'
import parse from 'html-react-parser'
import NewsletterForm from './newsletter-form'

class Footer extends Component {
  
  render() {
    let { wp } = this.props.data
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__logo'>
            <Link to='/' title='K Media' className='footer__logo'>
              <Symbol />
            </Link>
            <div className='footer__ticker'>
              { wp.siteOptions?.acf?.phrases?.map((phrase, i) => {
                return (
                  <h4 className='footer__ticker-item' key={i}>{parse(phrase.text)}</h4>
                )
              }) }
            </div>
          </div>

          <div className='footer__content'>
            <div className='footer__top'>
              <div className='footer__col'>
                <Link to='mailto:marketing@kmedia.com.au'>marketing@kmedia.com.au</Link>
              </div>

              <div className='footer__col'>
                <div>
                  <ul>
                    <li><Link to='/projects/'>Projects</Link></li>
                    <li><Link to='/services/'>Services</Link></li>
                    <li><Link to='/about-us/'>About</Link></li>
                    <li><Link to='/contact/'>Contact</Link></li>
                  </ul>
                </div>
              </div>
              <div className='footer__col'>
                <ul>
                  <li><Link to='https://www.instagram.com/k.mediagroup_/'>Instagram</Link></li>
                  <li><Link to='https://www.facebook.com/profile.php?id=100084190927228'>Facebook</Link></li>
                  <li><Link to='https://www.linkedin.com/company/k-media-group-1/'>Linkedin</Link></li>
                </ul>
              </div>
              <div className='footer__col'>
                <div className='footer__form'>
                  { wp.siteOptions?.acf?.newsletterText && <h4>{parse(wp.siteOptions?.acf?.newsletterText)}</h4> }
                  <NewsletterForm />
                </div>
              </div>
            </div>

            <div className='footer__bottom'>
              <div className='footer__col'>
                <p>© K Media {new Date().getFullYear()}. All rights reserved.</p>
              </div>

              <div className='footer__col'>
                &nbsp;
              </div>

              <div className='footer__col'>
                
              </div>
              <div className='footer__col'>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
