import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment WpSeo on WpPostTypeSEO {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        localFile {
          publicURL
        }
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
      schema {
        articleType
        pageType
        raw
      }
      title
      twitterDescription
      twitterImage {
        localFile {
          publicURL
        }
      }
      twitterTitle
    }
    fragment ProjectCard on WpProject {
      id
      uri
      title
      acf {
        client
        project
        website {
          url
          title
        }
        testimonial
        services
        brief
        solution
        outcome
        gallery {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1200
              )
            }
          }
          caption
        }
        thumb {
          video
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                )
              }
            }
          }
        }
      }
    }
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wp {
        generalSettings {
          title
          url
        }
        siteOptions {
          acf {
            gallery {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1200
                  )
                }
              }
            }
            phrases {
              text
            }
            newsletterText
          }
        }
      }
    }
  `)
}

export default useLayoutQuery