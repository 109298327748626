import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo } from './icons'
import { navigate } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = (e) => {
    let { scrolled, opacity, homeHeaderBg } = this.state

    const curScroll = window.scrollY || document.scrollTop
    scrolled = curScroll > 1

    requestAnimationFrame(() => {
      this.setState({ scrolled })
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _logoClick = () => {
    this.setState({ offCanvas: false })
    if (typeof document !== "undefined" && !document.body.classList.contains('overflow-hidden')) {
      navigate('/')
    }
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled } = this.state
    let { wp } = this.props.data

    let logoProps = {
      onClick: this._logoClick,
    }

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (offCanvas) {
      headerClass += ' header--menu-active'
      typeof document !== "undefined" && document.body.classList.add('menu-active')
    } else {
      typeof document !== "undefined" && document.body.classList.remove('menu-active')
    }

    if (this.props.pageContext?.header) headerClass += ` header--${this.props.pageContext.header}`

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <button className='header__logo' {...logoProps}>
              <Logo />
            </button>
            <nav className='header__nav'>
              <ul>
                <li><Link to='/projects/' {...props}>Projects</Link></li>
                <li><Link to='/services/' {...props}>Services</Link></li>
                <li><Link to='/about-us/' {...props}>About</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <nav className='off-canvas__nav'>
            <ul>
              <li><Link to='/projects/' {...props}>Projects</Link></li>
              <li><Link to='/services/' {...props}>Services</Link></li>
              <li><Link to='/about-us/' {...props}>About</Link></li>
              <li><Link to='/contact/' {...props}>Contact</Link></li>
            </ul>
          </nav>
          <div className='landing-banner__images-wrapper'>
            { wp.siteOptions?.acf?.gallery?.length > 0 &&
              <>
                <div className='landing-banner__images'>
                  { wp.siteOptions?.acf?.gallery.map((image, i) => {
                    return (
                      <div className='landing-banner__image' key={i}>
                        { image?.localFile && <GatsbyImage className='landing-banner__img' image={getImage(image.localFile)} alt={image.altText} /> }
                      </div>
                    )
                  }) }
                </div>
                <div className='landing-banner__images' ariaHidden="true">
                  { wp.siteOptions?.acf?.gallery.map((image, i) => {
                    return (
                      <div className='landing-banner__image' key={i}>
                        { image?.localFile && <GatsbyImage className='landing-banner__img' image={getImage(image.localFile)} alt={image.altText} /> }
                      </div>
                    )
                  }) }
                </div>
              </>
            }
          </div>
          <nav className='off-canvas__contact'>
            <ul>
              <li><Link to='tel:+61208085979' {...props}>+61 2 0808 5979</Link></li>
              <li><Link to='mailto:marketing@kmedia.com.au' {...props}>marketing@kmedia.com.au</Link></li>
            </ul>
          </nav>
        </div>
      </>
    )
  }
}

export default Header
