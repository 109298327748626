import React from 'react'

export const Logo = ({color = '#FFFFFF'}) => (
  <svg width="119" height="34" viewBox="0 0 119 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M24.3688 23.2916C26.6216 26.2635 28.7394 28.4172 30.721 29.7552C32.7014 31.0931 34.9057 31.8233 37.333 31.947L37.3701 32.3182C35.563 33.2589 33.9156 33.7304 32.4302 33.7304C30.9437 33.7304 29.6069 33.519 28.4188 33.098C25.4706 32.0326 22.4617 29.5931 19.3908 25.7807L9.62129 13.745L18.4252 2.08062C18.6234 1.83299 18.7224 1.61027 18.7224 1.41107C18.7224 1.21305 18.6853 1.0409 18.611 0.891116C18.4129 0.520939 18.0651 0.334198 17.5701 0.334198H17.1999V8.06675e-06H25.7069V0.334198H25.5572C23.8975 0.334198 22.5494 0.92827 21.5086 2.11661L14.2653 10.7349L24.3688 23.2916ZM9.13809 24.9452C9.60844 25.4031 10.1656 25.6315 10.8102 25.6315H11.2559L11.293 26.003H0V25.6315H0.482805C1.12629 25.6315 1.67776 25.4031 2.13683 24.9452C2.59493 24.4873 2.83556 23.9358 2.86045 23.2922V2.67412C2.83556 1.78302 2.42608 1.11464 1.63496 0.668986C1.28716 0.470962 0.903557 0.370783 0.482805 0.370783H0V0.000606367H11.293L11.2559 0.370783H10.8102C10.1656 0.370783 9.60844 0.594678 9.13809 1.04033C8.66773 1.48598 8.43255 2.03045 8.43255 2.67412V23.2922C8.43255 23.9358 8.66773 24.4873 9.13809 24.9452Z" fill={color}/>
    <g className='media'>
      <path d="M46.5759 19.2796L51.6155 8.32722V19.2796H53.8897V6.2168H50.3602L45.6663 16.4415L40.9724 6.2168H37.3701V19.2796H39.6443V8.32722L44.6838 19.2796H46.5759Z" fill={color}/>
      <path d="M59.4915 6.2168V19.2796H72.1176V17.242H61.7838V13.5669H71.0806V11.5293H61.7838V8.25445H72.1176V6.2168H59.4915Z" fill={color}/>
      <path d="M77.1 19.2796H83.7224C88.2161 19.2796 91.0907 16.7325 91.0907 12.7482C91.0907 8.76386 88.2161 6.2168 83.7224 6.2168H77.1V19.2796ZM79.3924 17.2783V8.21806H83.7042C86.8334 8.21806 88.7073 9.98282 88.7073 12.7482C88.7073 15.4954 86.8334 17.2783 83.7224 17.2783H79.3924Z" fill={color}/>
      <path d="M98.2454 6.2168H95.9167V19.2796H98.2454V6.2168Z" fill={color}/>
      <path d="M109.155 6.2168L102.496 19.2796H104.988L106.644 16.0048H114.231L115.886 19.2796H118.361L111.72 6.2168H109.155ZM110.446 8.50916L113.212 13.9672H107.681L110.446 8.50916Z" fill={color}/>
    </g>
  </svg>
)

export const Symbol = ({color = '#FFFFFF'}) => (
  <svg width="38" height="34" viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M24.3688 23.2915C26.6216 26.2635 28.7394 28.4172 30.721 29.7551C32.7014 31.0931 34.9057 31.8233 37.333 31.947L37.3701 32.3182C35.563 33.2589 33.9156 33.7304 32.4302 33.7304C30.9437 33.7304 29.6069 33.519 28.4188 33.098C25.4706 32.0326 22.4617 29.5931 19.3908 25.7807L9.62129 13.745L18.4252 2.08061C18.6234 1.83299 18.7224 1.61026 18.7224 1.41107C18.7224 1.21304 18.6853 1.04089 18.611 0.891108C18.4129 0.520931 18.0651 0.33419 17.5701 0.33419H17.1999V0H25.7069V0.33419H25.5572C23.8975 0.33419 22.5494 0.928262 21.5086 2.1166L14.2653 10.7349L24.3688 23.2915ZM9.13809 24.9452C9.60844 25.4031 10.1656 25.6315 10.8102 25.6315H11.2559L11.293 26.003H0V25.6315H0.482805C1.12629 25.6315 1.67776 25.4031 2.13683 24.9452C2.59493 24.4873 2.83556 23.9358 2.86045 23.2922V2.67412C2.83556 1.78301 2.42608 1.11463 1.63496 0.668978C1.28716 0.470954 0.903557 0.370775 0.482805 0.370775H0V0.0005983H11.293L11.2559 0.370775H10.8102C10.1656 0.370775 9.60844 0.59467 9.13809 1.04032C8.66773 1.48597 8.43255 2.03044 8.43255 2.67412V23.2922C8.43255 23.9358 8.66773 24.4873 9.13809 24.9452Z" fill={color}/>
  </svg>
)

export const Plus = ({color = '#FFFFFF'}) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.6002 0.200012V6.60001H0.200195V7.40001H6.6002V13.8H7.4002V7.40001H13.8002V6.60001H7.4002V0.200012H6.6002Z" fill={color}/>
  </svg>
)

export const Minus = ({color = '#FFFFFF'}) => (
  <svg width="14" height="1" viewBox="0 0 14 1" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14 1H0V0H14V1Z" fill={color}/>
  </svg>
)

export const LongArrow = ({color = '#FFFFFF'}) => (
  <svg width="40" height="8" viewBox="0 0 40 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M36.7127 0.146447L39.8554 3.32843C40.0482 3.52369 40.0482 3.84027 39.8554 4.03553L36.7127 7.21751C36.5198 7.41278 36.2071 7.41278 36.0143 7.21751C35.8214 7.02225 35.8214 6.70567 36.0143 6.51041L38.314 4.18198L0 4.18198L0 3.18198L38.314 3.18198L36.0143 0.853553C35.8214 0.658291 35.8214 0.341709 36.0143 0.146447C36.2071 -0.0488155 36.5198 -0.0488155 36.7127 0.146447Z" fill={color}/>
  </svg>
)